import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../../components/layout/Layout';
import ClientCard from '../../components/card/Client';
import PaginationBlock from '../../components/block/Pagination';

// Clients
const ClientsPage = ({ pageContext, data }) => {

  const { t } = useTranslation();
  const { totalCount } = data.clients;

  const pageSchemas = [
    {
      type: "breadcrumb",
      listItems: [
        {
          name: t('breadcrumb.clients'),
        }
      ]
    }
  ];

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title })}
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title })}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item active">{t('breadcrumb.clients')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div>
          <div className="row gx-3 mb-7">
            {data.clients.nodes &&
              data.clients.nodes.map(client => (
                <ClientCard key={client.id} data={client} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4" />
              ))
            }
          </div>
          <div>
            <PaginationBlock
              path={`clients`}
              currentPage={pageContext.currentPage}
              numPages={pageContext.numPages}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

ClientsPage.propTypes = {
  data: PropTypes.shape({
  }),
}

export const query = graphql`
  query ($language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    clients: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/clients/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          name
          description
          country
          website_url
          social_networks {
            google
            linkedIn
            facebook
            instagram
            twitter
          }
          image {
            logo {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData(width: 1600)
              }
            }
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "D MMMM YYYY")
        }
        id
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "clients", "countries", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ClientsPage