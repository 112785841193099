import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';

const ClientCard = ({ data, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up">
          <div className="card h-100">
            <Link to={`/client/${data.frontmatter.path}/`} className="stretched-link"></Link>
            <div className="shape-container">
              <GatsbyImage
                image={getImage(data.frontmatter.image.card)}
                className="card-img-top"
                alt={data.frontmatter.image.alt}
              />
              <div className="shape shape-bottom zi-1" style={{ marginBottom: '-.25rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                </svg>
              </div>
            </div>
            <div className="card-body">
              {data.frontmatter.name &&
                <h3 className="card-title text-dark">{data.frontmatter.name}</h3>
              }
              {data.frontmatter.description &&
                <p className="card-text">
                  {data.frontmatter.description}
                </p>
              }
            </div>
            <div className="card-footer">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  {data.frontmatter.social_networks &&
                    <ul className="list-inline text-center mb-0">
                      {data.frontmatter.social_networks.google &&
                        <li className="list-inline-item">
                          <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.google}>
                            <i className="bi-google"></i>
                          </a>
                        </li>
                      }
                      {data.frontmatter.social_networks.linkedIn &&
                        <li className="list-inline-item">
                          <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.linkedIn}>
                            <i className="bi-linkedin"></i>
                          </a>
                        </li>
                      }
                      {data.frontmatter.social_networks.facebook &&
                        <li className="list-inline-item">
                          <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.facebook}>
                            <i className="bi-facebook"></i>
                          </a>
                        </li>
                      }
                      {data.frontmatter.social_networks.instagram &&
                        <li className="list-inline-item">
                          <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.instagram}>
                            <i className="bi-instagram"></i>
                          </a>
                        </li>
                      }
                      {data.frontmatter.social_networks.twitter &&
                        <li className="list-inline-item">
                          <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.twitter}>
                            <i className="bi-twitter"></i>
                          </a>
                        </li>
                      }
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up">
          <div className="card card-flush h-100">
            <Link to={`/client/${data.frontmatter.path}/`} className="stretched-link"></Link>
            <div className="shape-container">
              <GatsbyImage
                image={getImage(data.frontmatter.image.card)}
                className="card-img"
                alt={data.frontmatter.image.alt}
              />
            </div>
            <div className="card-body">
              {data.frontmatter.name &&
                <h4 className="card-title text-inherit">{data.frontmatter.name}</h4>
              }
              {data.frontmatter.description &&
                <p className="card-text text-body">
                  {data.frontmatter.description}
                </p>
              }
              {data.frontmatter.social_networks &&
                <ul className="list-inline text-center mb-0">
                  {data.frontmatter.social_networks.google &&
                    <li className="list-inline-item">
                      <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.google}>
                        <i className="bi-google"></i>
                      </a>
                    </li>
                  }
                  {data.frontmatter.social_networks.linkedIn &&
                    <li className="list-inline-item">
                      <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.linkedIn}>
                        <i className="bi-linkedin"></i>
                      </a>
                    </li>
                  }
                  {data.frontmatter.social_networks.facebook &&
                    <li className="list-inline-item">
                      <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.facebook}>
                        <i className="bi-facebook"></i>
                      </a>
                    </li>
                  }
                  {data.frontmatter.social_networks.instagram &&
                    <li className="list-inline-item">
                      <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.instagram}>
                        <i className="bi-instagram"></i>
                      </a>
                    </li>
                  }
                  {data.frontmatter.social_networks.twitter &&
                    <li className="list-inline-item">
                      <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.twitter}>
                        <i className="bi-twitter"></i>
                      </a>
                    </li>
                  }
                </ul>
              }
            </div>
          </div>
        </div>
      }
      {cardStyle === "3" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up">
          <div className="card card-transition h-100">
            <Link to={`/client/${data.frontmatter.path}/`} className="stretched-link"></Link>
            <div className="card-body">
              <div className="d-sm-flex">
                <div className="flex-shrink-0 mb-3 mb-sm-0">
                  <div className="avatar avatar-lg avatar-circle mb-2">
                    <GatsbyImage
                      image={getImage(data.frontmatter.image.logo)}
                      className="avatar-img border"
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                </div>
                <div className="flex-grow-1 ms-sm-4">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    {data.frontmatter.name &&
                      <h4 className="card-title text-inherit">{data.frontmatter.name}</h4>
                    }
                  </div>
                  {data.frontmatter.social_networks &&
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <ul className="list-inline text-center mb-0">
                        {data.frontmatter.social_networks.google &&
                          <li className="list-inline-item">
                            <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.google}>
                              <i className="bi-google"></i>
                            </a>
                          </li>
                        }
                        {data.frontmatter.social_networks.linkedIn &&
                          <li className="list-inline-item">
                            <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.linkedIn}>
                              <i className="bi-linkedin"></i>
                            </a>
                          </li>
                        }
                        {data.frontmatter.social_networks.facebook &&
                          <li className="list-inline-item">
                            <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.facebook}>
                              <i className="bi-facebook"></i>
                            </a>
                          </li>
                        }
                        {data.frontmatter.social_networks.instagram &&
                          <li className="list-inline-item">
                            <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.instagram}>
                              <i className="bi-instagram"></i>
                            </a>
                          </li>
                        }
                        {data.frontmatter.social_networks.twitter &&
                          <li className="list-inline-item">
                            <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.twitter}>
                              <i className="bi-twitter"></i>
                            </a>
                          </li>
                        }
                      </ul>
                    </div>
                  }
                </div>
              </div>
              {data.frontmatter.description &&
                <p className="card-text text-body">
                  {data.frontmatter.description}
                </p>
              }
            </div>
          </div>
        </div>
      }
      {cardStyle === "4" &&
        <div key={data.id} className="col-sm-6 col-md-3 mb-5 mb-lg-0" data-aos="fade-up">
          <div>
            <Link to={`/client/${data.frontmatter.path}/`} className="stretched-link"></Link>
            <GatsbyImage
              image={getImage(data.frontmatter.image.card)}
              className="card-img rounded-2"
              alt={data.frontmatter.image.alt}
            />
            <div className="card card-sm text-center mt-n7 mx-3">
              <div className="card-body">
                {data.frontmatter.name &&
                  <h4 className="card-title">{data.frontmatter.name}</h4>
                }
                {data.frontmatter.position &&
                  <p className="card-text">
                    {data.frontmatter.position}
                  </p>
                }
                {data.frontmatter.social_networks &&
                  <ul className="list-inline text-center mb-0">
                    {data.frontmatter.social_networks.google &&
                      <li className="list-inline-item">
                        <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.google}>
                          <i className="bi-google"></i>
                        </a>
                      </li>
                    }
                    {data.frontmatter.social_networks.linkedIn &&
                      <li className="list-inline-item">
                        <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.linkedIn}>
                          <i className="bi-linkedin"></i>
                        </a>
                      </li>
                    }
                    {data.frontmatter.social_networks.facebook &&
                      <li className="list-inline-item">
                        <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.facebook}>
                          <i className="bi-facebook"></i>
                        </a>
                      </li>
                    }
                    {data.frontmatter.social_networks.instagram &&
                      <li className="list-inline-item">
                        <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.instagram}>
                          <i className="bi-instagram"></i>
                        </a>
                      </li>
                    }
                    {data.frontmatter.social_networks.twitter &&
                      <li className="list-inline-item">
                        <a className="btn btn-outline-primary btn-xs btn-icon rounded zi-1" target="_blank" rel="noopener noreferrer" href={data.frontmatter.social_networks.twitter}>
                          <i className="bi-twitter"></i>
                        </a>
                      </li>
                    }
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ClientCard